.float-right {
    float: right;
    // margin-top: 20px !important;
    // margin-left: 20px !important;
    img {
        height: 96px !important;
    }
}

.float-left {
    float: left;
    // margin-top: 15px !important;
    // margin-right: 20px !important;
    img {
        height: 96px !important;
    }
}