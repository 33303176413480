footer {
  height: 2rem;
  width: 100%;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem 0;

  .footer-container {
    font-size: .875rem;

    .footer-line {
      color: rgb(231, 231, 231);
      [theme=dark] & {
        color: rgb(53, 53, 53);
      }
      width: 100%;

      a {
        color: rgb(231, 231, 231);
        [theme=dark] & {
          color: rgb(53, 53, 53);
        }
      }
      .icp-br {
        display: none;
      }
    }
  }

  @include blur;
}
